import "./App.css";
import styled from "styled-components";
import { AppShell, Burger, Flex, Image } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import MainRoutes from "./routes/MainRoutes";
import { assets, colors } from "./constants";
import { Sidebar, Header } from "./layout";
import { useAppData } from "./contexts/AppContext";
import {
  HIDE_LAYOUT_FOR_ROUTES,
  HIDE_SIDEBAR_FOR_ROUTES,
} from "./constants/global";
import { useAutoLogout } from './hooks/useAutoLogout';
import {useEffect} from "react";
function App() {
  const [opened, { toggle }] = useDisclosure();
  const { version } = require('../package.json');
  const {
    currentRouteData: { currentRoute },
  } = useAppData();
  useAutoLogout();
    useEffect(() => {
        console.log("Mode :", process.env.REACT_APP_ENV);
        console.log("Version :", version);
    },[]);
  return HIDE_LAYOUT_FOR_ROUTES.includes(currentRoute.path) ? (
    <MainRoutes />
  ) : (
    <AppShell
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: {
          mobile: !opened,
          desktop: HIDE_SIDEBAR_FOR_ROUTES.includes(currentRoute.path),
        },
      }}
      padding="md">
      <AppShell.Header hidden>
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        <Image w={133} h={128} src={assets.images.logo} />
      </AppShell.Header>
      <AppShell.Navbar
        bg={colors.bg}
        style={{
          borderRight: "none",
          height: "100vh", // Ensure navbar height is 100% of viewport height
          overflowY: "auto", // Allow vertical scrolling if content overflows
        }}
        p={0}>
        <Sidebar />
      </AppShell.Navbar>

      <AppShell.Main bg={colors.bg}>
        <Header
          showLogo={HIDE_SIDEBAR_FOR_ROUTES.includes(currentRoute.path)}
        />
        <Flex>
          <MainRoutes />
        </Flex>
      </AppShell.Main>
    </AppShell>
  );
}

export default App;

const Container = styled.div`
  background-color: #f8f3f0;
  height: 100vh;
`;
