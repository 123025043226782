import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as signOutFromFirebase,
} from "@firebase/auth";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  where,
  query,
  getDocs,
  getDoc,
  deleteDoc,
  DocumentData,
  writeBatch,
  serverTimestamp,
  updateDoc,
  orderBy,
  limit,
  collectionGroup,
  onSnapshot,
} from "@firebase/firestore";
import { FirebaseResponse } from "../constants/types/general";
import { ref, getDownloadURL, getStorage, uploadBytes } from "firebase/storage";
import { auth, db } from "../firebase";
import { COLLECTIONS } from "../constants/global";
import { convertTimestamp } from "../modules";

export const signUp = async (
  email: string,
  password: string
): Promise<FirebaseResponse> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      email: user.email,
    });
    return { success: true, message: "Signed up successfully!" };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};
export const signIn = async (
  email: string,
  password: string
): Promise<FirebaseResponse> => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return { success: true, message: "Signed in successfully!", data: user };
  } catch (error: any) {
    return { success: false, message: error.message, code: error?.code };
  }
};

export const signOut = async (): Promise<FirebaseResponse> => {
  try {
    await signOutFromFirebase(auth);
    return { success: true, message: "Logged out successfully!" };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

/**
 * Upsert a document in a collection
 * @param collectionName  collection name
 * @param data  data to be inserted
 * @param documentId  document id (optional)
 * @returns FirebaseResponse
 */

export const upsertDoc = async <T>(
  collectionName: COLLECTIONS,
  data: T,
  documentId?: string,
  ext?: { createdAt?: boolean; updatedAt?: boolean }
): Promise<FirebaseResponse> => {
  let { createdAt = false, updatedAt = false } = ext || {};
  try {
    let response: any = undefined;
    let dataWithTimestamp: any = { ...data };

    if (createdAt && !documentId) {
      dataWithTimestamp.createdAt = serverTimestamp();
    }
    if (updatedAt) {
      dataWithTimestamp.updatedAt = serverTimestamp();
    }

    if (documentId) {
      const documentRef = doc(db, collectionName, documentId);
      await setDoc(documentRef, dataWithTimestamp, { merge: true });
    } else {
      const collectionRef = collection(db, collectionName);
      response = await addDoc(collectionRef, dataWithTimestamp);
    }

    return {
      success: true,
      message: "Data updated successfully!",
      data: response,
    };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const addSubcollectionDoc = async <T>(
  collectionName: COLLECTIONS,
  docId: string = "",
  subcollectionName: string,
  data: T,
  {
    createdAt = false,
    updatedAt = false,
  }: { createdAt?: boolean; updatedAt?: boolean }
): Promise<FirebaseResponse> => {
  try {
    if (!docId) return { success: false, message: "Missing parent doc id." };
    let response: any = undefined;
    let dataWithTimestamp: any = { ...data };

    if (createdAt) {
      dataWithTimestamp.createdAt = serverTimestamp();
    }
    if (updatedAt) {
      dataWithTimestamp.updatedAt = serverTimestamp();
    }

    const subcollectionRef = collection(
      db,
      collectionName,
      docId,
      subcollectionName
    );
    response = await addDoc(subcollectionRef, dataWithTimestamp);

    return {
      success: true,
      message: "Subcollection document added successfully!",
      data: response,
    };
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

export const getSubcollectionDoc = async <T>(
    collectionName: COLLECTIONS,
    docId: string,
    subcollectionName: string
): Promise<T[]> => {
  try {
    if (!docId) return [];

    const subcollectionRef = collection(
        db,
        collectionName,
        docId,
        subcollectionName
    );

    const querySnapshot = await getDocs(subcollectionRef);

    if (querySnapshot.empty) {
      return [];
    } else {
      return querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as T[];
    }
  } catch (error) {
    console.error(`Error fetching subcollection ${subcollectionName}:`, error);
    return [];
  }
};
export const upsertDocWhere = async <T>(
  collectionName: COLLECTIONS,
  data: T,
  queryKey: keyof T,
  queryValue: any
): Promise<FirebaseResponse> => {
  try {
    const q = query(
      collection(db, collectionName),
      where(queryKey as string, "==", queryValue)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty && querySnapshot.docs.length) {
      let documentId = querySnapshot.docs[0].id;
      const documentRef = doc(db, collectionName, documentId);
      await setDoc(documentRef, data || {}, { merge: true });
      return { success: true, message: "Data updated successfully!" };
    } else {
      return { success: false, message: "Could not find record" };
    }
  } catch (error: any) {
    return { success: false, message: error.message };
  }
};

/**
 *  Get a document
 * @param collectionName  collection name
 * @param queryKey  query key
 * @param queryValue  query value
 * @returns  document data
 */
export async function getDocByQuery<T>(
  collectionName: COLLECTIONS,
  queryKey: keyof T,
  queryValue: any
): Promise<T | undefined> {
  const q = query(
    collection(db, collectionName),
    where(queryKey as string, "==", queryValue)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return undefined;
  } else {
    return {
      id: querySnapshot.docs[0].id,
      ...querySnapshot.docs[0].data(),
    } as T;
  }
}

/**
 *  Get a document
 * @param collectionName  collection name
 * @param id  id of doc to delete
 * @returns  document data
 */
export async function deleteDocFromCol(
  collectionName: COLLECTIONS,
  id: string
): Promise<boolean> {
  try {
    // delete doc
    await deleteDoc(doc(db, collectionName, id));
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Delete all documents from a specified collection.
 * @param collectionName The name of the collection to delete documents from.
 * @returns Promise<void>
 */
export async function deleteAllDocsFromCollection(
  collectionName: string
): Promise<void> {
  const collectionRef = collection(db, collectionName);
  const batchSize = 500; // Firestore batch write limit

  const deleteBatch = async () => {
    // Query the first batch of documents
    const q = query(collectionRef);
    const snapshot = await getDocs(q);
    const docs = snapshot.docs;

    // If there are no documents left, we are done
    if (docs.length === 0) {
      return;
    }

    // Start a new batch
    const batch = writeBatch(db);

    docs.forEach((doc) => batch.delete(doc.ref));

    // Commit the batch
    await batch.commit();

    // If we deleted the maximum amount of documents, there might be more to delete
    if (docs.length >= batchSize) {
      await deleteBatch(); // Recursively delete the next batch
    }
  };

  await deleteBatch();
}

/**
 *  Get a document
 * @param collectionName  collection name
 * @param queryKey  query key
 * @param queryValue  query value
 * @returns  document data
 */
export async function getDocsByQuery<T>(
  collectionName: COLLECTIONS,
  queryKey: keyof T,
  queryValue: any
): Promise<T[]> {
  const q = query(
    collection(db, collectionName),
    where(queryKey as string, "==", queryValue)
  );
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as T[];
  }
}

/**
 * Get a document
 * @param collectionName Collection name
 * @param nestedCollectionName Optional nested collection name
 * @param queryKey Query key
 * @param queryValue Query value
 * @returns Document data
 */
export async function getAllDocs<T>(
  collectionName: COLLECTIONS,
  nestedCollectionName?: string
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (nestedCollectionName) {
        const nestedCollectionRef = collection(
          db,
          collectionName,
          doc.id,
          nestedCollectionName
        );

        const nestedQuerySnapshot = await getDocs(nestedCollectionRef);

        docData[nestedCollectionName] = nestedQuerySnapshot.docs.map(
          (nestedDoc) => ({
            id: nestedDoc.id,
            ...nestedDoc.data(),
          })
        );
      }

      result.push(docData as T);
    }

    return result;
  }
}

export function subscribeToAllDocs<T>(
  collectionName: COLLECTIONS,
  onUpdate: (data: T[]) => void,
  nestedCollectionName?: string
): () => void {
  const q = query(collection(db, collectionName));

  const unsubscribe = onSnapshot(
    q,
    async (querySnapshot) => {
      const result: T[] = [];

      for (const doc of querySnapshot.docs) {
        try {
          const docData: DocumentData = {
            id: doc.id,
            ...doc.data(),
            dateOfBirth: convertTimestamp(doc.data().dateOfBirth as any), // Convert timestamp here
          };

          if (nestedCollectionName) {
            const nestedCollectionRef = collection(
              db,
              collectionName,
              doc.id,
              nestedCollectionName
            );

            const nestedQuerySnapshot = await getDocs(nestedCollectionRef);
            docData[nestedCollectionName] = nestedQuerySnapshot.docs.map(
              (nestedDoc) => ({
                id: nestedDoc.id,
                ...nestedDoc.data(),
              })
            );
          }
          result.push(docData as T);
        } catch (error) {
          console.log("Error fetching nestedQuerySnapshot", error);
        }
      }

      onUpdate(result); // Call the callback function with the result
    },
    (error) => {
      console.error("Error fetching real-time documents:", error);
    }
  );

  return unsubscribe; // Return the unsubscribe function to stop listening when needed
}

export async function uploadFileToFirebaseStorage(
  file: File,
  fileName: string,
  folder: string = "uploads"
): Promise<string> {
  try {
    // Get the Firebase Storage service
    const storage = getStorage();

    // Create a reference to the file in Firebase Storage
    const fileRef = ref(storage, `${folder}/${fileName}`);

    // Upload the file to Firebase Storage
    await uploadBytes(fileRef, file);

    // Get URL for the file to show in background
    const url = await getDownloadURL(fileRef);

    return url;
  } catch (e) {
    console.log(e);

    return "";
  }
}

/**
 * Get a document with support for nested collections at any level
 * @param collectionName Collection name
 * @param nestedCollectionNames Array of nested collection names
 * @returns Document data
 */
export async function getAllDocsNestedRecursively<T>(
  collectionName: COLLECTIONS,
  nestedCollectionNames?: string[]
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (nestedCollectionNames && nestedCollectionNames.length > 0) {
        await processNestedCollections(docData, doc.id, nestedCollectionNames);
      }

      result.push(docData as T);
    }

    return result;
  }
}

/**
 * Recursively process nested collections
 * @param data Current data object
 * @param parentId Parent document ID
 * @param nestedCollectionNames Array of nested collection names
 */
async function processNestedCollections(
  data: DocumentData,
  parentId: string,
  nestedCollectionNames: string[]
): Promise<void> {
  for (const nestedCollectionName of nestedCollectionNames) {
    const nestedCollectionRef = collection(
      db,
      data.id,
      parentId,
      nestedCollectionName
    );

    const nestedQuerySnapshot = await getDocs(nestedCollectionRef);

    data[nestedCollectionName] = await Promise.all(
      nestedQuerySnapshot.docs.map(async (nestedDoc) => {
        const nestedDocData: DocumentData = {
          id: nestedDoc.id,
          ...nestedDoc.data(),
        };

        // Recursively process deeper nested collections
        await processNestedCollections(
          nestedDocData,
          nestedDoc.id,
          nestedCollectionNames.slice(1)
        );

        return nestedDocData;
      })
    );
  }
}

/**
 * Get a document
 * @param collectionName Collection name
 * @param firstNestedCollectionName Optional first nested collection name
 * @param secondNestedCollectionName Optional second nested collection name
 * @param queryKey Query key
 * @param queryValue Query value
 * @returns Document data
 */
export async function getAllDocsNested<T>(
  collectionName: COLLECTIONS,
  firstNestedCollectionName?: string,
  secondNestedCollectionName?: string
): Promise<T[]> {
  const q = query(collection(db, collectionName));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  } else {
    const result: T[] = [];

    for (const doc of querySnapshot.docs) {
      const docData: DocumentData = {
        id: doc.id,
        ...doc.data(),
      };

      if (firstNestedCollectionName) {
        const firstNestedCollectionRef = collection(
          db,
          collectionName,
          doc.id,
          firstNestedCollectionName
        );

        const firstNestedQuerySnapshot = await getDocs(
          firstNestedCollectionRef
        );

        docData[firstNestedCollectionName] = firstNestedQuerySnapshot.docs.map(
          (firstNestedDoc) => ({
            id: firstNestedDoc.id,
            ...firstNestedDoc.data(),
          })
        );

        if (secondNestedCollectionName) {
          // Add support for the second level of nesting
          for (const nestedDoc of firstNestedQuerySnapshot.docs) {
            const secondNestedCollectionRef = collection(
              db,
              collectionName,
              doc.id,
              firstNestedCollectionName,
              nestedDoc.id,
              secondNestedCollectionName
            );

            const secondNestedQuerySnapshot = await getDocs(
              secondNestedCollectionRef
            );

            const nestedData = docData[firstNestedCollectionName].find(
              (item: any) => item.id === nestedDoc.id
            );

            if (nestedData) {
              nestedData[secondNestedCollectionName] =
                secondNestedQuerySnapshot.docs.map((secondNestedDoc) => ({
                  id: secondNestedDoc.id,
                  ...secondNestedDoc.data(),
                }));
            }
          }
        }
      }

      result.push(docData as T);
    }

    return result;
  }
}

/**
 * Create or update a chat thread.
 * @param {string} userId1 The first user's ID.
 * @param {string} userId2 The second user's ID.
 * @param {object} lastMessage An object containing the last message's data.
 */
export const upsertChatThread = async (userId1, userId2, lastMessage) => {
  const threadId = [userId1, userId2].sort().join("_"); // Ensures consistency in thread ID generation
  try {
    const threadRef = doc(db, "message-thread", threadId); // Corrected way to get a document reference

    const threadSnapshot = await getDoc(threadRef); // use getDocs to fetch document snapshot

    if (threadSnapshot.exists()) {
      console.log("here2", threadSnapshot.data());
      // Update the last message and timestamp if the thread exists
      await setDoc(
        threadRef,
        {
          lastMessage: lastMessage,
          lastMessageSentBy: userId1,
          lastMessageTimestamp: new Date(),
          lastUpdated: serverTimestamp(), // Use Firestore server timestamp
        },
        { merge: true }
      ); // Use merge option to update existing data
    } else {
      console.log("here");
      // Create a new thread if it doesn't exist
      await setDoc(threadRef, {
        users: [userId1, userId2],
        lastMessage: lastMessage,
        lastMessageSentBy: userId1,
        lastMessageTimestamp: new Date(),
        lastUpdated: serverTimestamp(), // Use Firestore server timestamp
        unreadCount: { [userId1]: 0, [userId2]: 1 }, // Assuming the sender has read the message
      });
    }
  } catch (error: any) {
    console.error("Error upserting chat thread:", error);
    return null;
  }
  return threadId;
};

/**
 * Send a message in a chat thread.
 * @param {string} threadId The chat thread's ID.
 * @param {string} senderId The sender's user ID.
 * @param {string} content The message text.
 */
export const sendMessage = async (
  threadId,
  senderId,
  receiverId,
  messageId,
  content
) => {
  const threadRef = doc(db, "message-thread", threadId);
  // const messagesRef = collection(threadRef, 'messages');
  const messagesRef = doc(db, `message-thread/${threadId}/messages`, messageId);

  const message = {
    content,
    threadId,
    sentBy: senderId,
    timestamp: serverTimestamp(),
    isDeleted: false,
    read: false,
  };

  // Add the message to the thread's messages sub-collection
  // await addDoc(messagesRef, message);
  await setDoc(messagesRef, message);

  // Update the thread's last message and last updated timestamp
  await updateDoc(threadRef, {
    lastMessage: content,
    lastMessageSentBy: senderId,
    lastMessageTimestamp: new Date(),
    lastUpdated: serverTimestamp(),
    // Increment unread count for the recipient
    // This requires custom logic based on your application's requirements
  });
  const mostRecentThread = {
    id: threadId,
    lastMessage: content,
    lastMessageSentBy: senderId,
    lastMessageTimestamp: new Date(),
    lastUpdated: serverTimestamp(),
    users: [senderId, receiverId],
  };
  return mostRecentThread;
};

/**
 * Reply to a message if it exists in any chat thread.
 * @param {string} messageId The ID of the message to check.
 * @param {string} senderId The ID of the user sending the new message.
 * @param {string} replyContent The content of the reply message.
 */

export const replyToMessageIfExists = async (phone) => {
  const usersRef = collection(db, "users");
  const userQuery = query(usersRef, where("phone", "==", phone));

  const querySnapshot = await getDocs(userQuery);

  const userData = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  if (userData.length > 0) {
    const userId = userData[0].id;
    const threadsRef = collection(db, "message-thread");
    const threadsQuery = query(
      threadsRef,
      where("users", "array-contains", userId),
      orderBy("lastUpdated", "desc")
    );
    const querySnapshot = await getDocs(threadsQuery);
    const thread = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const threadId = thread[0].id;
    const message = {
      content: "reply text2",
      threadId,
      sentBy: userId,
      timestamp: serverTimestamp(),
      isDeleted: false,
      read: false,
    };
    // const messageId="test";
    // const messagesRef = doc(db, `message-thread/${threadId}/messages`, messageId);
    // await setDoc(messagesRef, message);
    const threadRef = doc(db, "message-thread", threadId);
    const messagesRef = collection(threadRef, "messages");
    // await addDoc(messagesRef, message);
    // console.log('thread',threadId);
  }
};
export const replyToMessageIfExists2 = async (messageId) => {
  const messagesQuery = query(
    collectionGroup(db, "messages"),
    where("id", "==", messageId) // Use '__name__' to query by document ID
  );
  // const messageQuery = db.collectionGroup('messages').where('messageId', '==', messageId);
  const messageQuerySnapshot = await getDocs(messagesQuery);
  console.log("messageQuerySnapshot", messageQuerySnapshot);
  return;

  if (!messageQuerySnapshot.empty) {
    const messageDoc = messageQuerySnapshot.docs[0]; // Assuming messageId is unique and using the first doc
    const messageData = messageDoc?.data();
    const threadId = messageData?.threadId;
    const senderId = messageData?.senderId;

    if (threadId) {
      const newMessageRef = doc(
        db,
        `message-thread/${threadId}/messages`,
        serverTimestamp().toString()
      );
      const newMessage = {
        content: "testing reply",
        sentBy: senderId,
        timestamp: serverTimestamp(),
        isDeleted: false,
        read: false,
      };
      // Set the new message document
      await setDoc(newMessageRef, newMessage);
      console.log(
        "New message sent in response to",
        messageId,
        "in thread",
        threadId
      );
    } else {
      console.log("No thread associated with this message.");
    }
  } else {
    console.log("Message with ID", messageId, "does not exist.");
  }
};

/**
 * Fetch chat threads for a user.
 * @param {string} userId The user's ID.
 */
export const fetchChatThreadsForUser = async (userId) => {
  const threadsRef = collection(db, "message-thread");
  const threadsQuery = query(
    threadsRef,
    where("users", "array-contains", userId),
    orderBy("lastUpdated", "desc")
  );

  const querySnapshot = await getDocs(threadsQuery);

  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

/**
 * Fetch messages within a chat thread.
 * @param {string} threadId The chat thread's ID.
 */
export const fetchMessages2 = async (threadId) => {
  const threadRef = doc(db, "message-thread", threadId);
  const messagesRef = collection(threadRef, "messages");
  const messagesQuery = query(messagesRef, orderBy("timestamp", "asc"));

  const querySnapshot = await getDocs(messagesQuery);

  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const fetchMessages = (
  threadId: string,
  onMessageReceived: (messages: any[]) => void
) => {
  if (!threadId) return () => {};
  const threadRef = doc(db, "message-thread", threadId);
  const messagesRef = collection(threadRef, "messages");
  const messagesQuery = query(messagesRef, orderBy("timestamp", "asc"));

  // if (threadId) {
  //   updateDoc(threadRef, {
  //     unreadMessages: 0,
  //   });
  // }

  const unsubscribe = onSnapshot(
    messagesQuery,
    (querySnapshot) => {
      const messages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      onMessageReceived(messages);
    },
    (error) => {
      console.error("Error fetching messages:", error);
    }
  );

  return unsubscribe;
};

/**
 * Fetch all users and their chat threads.
 */
export const fetchAllUsersWithChatThreads = async () => {
  const usersRef = collection(db, "users");
  const usersSnapshot = await getDocs(usersRef);

  // Map over each user document, fetch their most recent thread, and construct the user object
  const usersWithThreads = await Promise.all(
    usersSnapshot.docs.map(async (userDoc) => {
      const user = { id: userDoc.id, ...userDoc.data() };

      // Fetch the most recent chat thread where the user is a participant
      const threadsRef = collection(db, "message-thread");
      const threadsQuery = query(
        threadsRef,
        where("users", "array-contains", user.id),
        orderBy("lastUpdated", "desc"),
        limit(1) // Only fetch the most recent thread
      );
      const threadsSnapshot = await getDocs(threadsQuery);

      // Get the most recent thread as an object or null if no threads exist
      const mostRecentThread = threadsSnapshot.docs[0]
        ? {
            id: threadsSnapshot.docs[0].id,
           lastMessageTimestamp: threadsSnapshot.docs[0].data().lastMessageTimestamp,
            unreadMessages: threadsSnapshot.docs[0].data().unreadMessages,
            ...threadsSnapshot.docs[0].data(),
          }
        : null;

      return { ...user, mostRecentThread };
    })
  );

  // Sort users based on the lastMessageTimestamp and prioritize those with unread messages
  usersWithThreads.sort((a, b) => {
    // Prioritize users with unread messages
    if (a.mostRecentThread?.unreadMessages > 0 && b.mostRecentThread?.unreadMessages <= 0) {
      return -1;
    } else if (b.mostRecentThread?.unreadMessages > 0 && a.mostRecentThread?.unreadMessages <= 0) {
      return 1;
    }

    // Compare by lastMessageTimestamp, assuming they are Firebase Timestamps
    if (a.mostRecentThread && b.mostRecentThread) {
      // Convert Firebase Timestamp to JavaScript Date and get milliseconds
      const aTimestamp = a.mostRecentThread.lastMessageTimestamp.toDate().getTime();
      const bTimestamp = b.mostRecentThread.lastMessageTimestamp.toDate().getTime();
      return bTimestamp - aTimestamp;  // More recent dates will have higher timestamps
    } else if (a.mostRecentThread && !b.mostRecentThread) {
      return -1;  // Users with threads should come before users without
    } else if (!a.mostRecentThread && b.mostRecentThread) {
      return 1;  // Users with threads should come before users without
    }

    return 0;  // If both users don't have threads, they are equivalent
  });


  return usersWithThreads;

  // Sort users such that users with chat threads appear on top
  // usersWithThreads.sort((a, b) => (b.mostRecentThread ? 1 : -1));
  // return usersWithThreads;
};
export const subscribeToUserInfo = (userId, onUpdate) => {
  const userRef = doc(db, "users", userId);
  return onSnapshot(userRef, (doc) => {
    onUpdate({ id: doc.id, ...doc.data() });
  });
};
export const subscribeToThreadUpdates = (threadId, onUpdate) => {
  const threadRef = doc(db, "message-thread", threadId);
  return onSnapshot(threadRef, (doc) => {
    onUpdate({ id: doc.id, ...doc.data() }); // Pass the updated thread back to the caller
  });
};
export async function getPaginatedDocs<T>(
    collectionName: COLLECTIONS,
    pageNumber: number,
    pageSize: number,
    searchTerm?: string
): Promise<{ docs: T[]; totalPages: number }> {
  try {

    let q = query(collection(db, collectionName));
    //debugger;
    if (searchTerm && collectionName === 'users') {
      const lowerSearchTerm = searchTerm.toLowerCase().trim();
      q = query(
          q,
          where('searchTerms', 'array-contains', lowerSearchTerm)
      );
    }

    const start = (pageNumber - 1) * pageSize;
    const end = start + pageSize;

    const querySnapshot = await getDocs(q);
    const totalDocs = querySnapshot.docs.length;

    if (totalDocs === 0) {
      return { docs: [], totalPages: 0 };
    }

    const paginatedDocs = querySnapshot.docs.slice(start, end).map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const totalPages = Math.ceil(totalDocs / pageSize);

    return { docs: paginatedDocs as T[], totalPages };
  } catch (error) {
    // handle error
  }
}
