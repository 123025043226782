import {useEffect, useState} from "react";
import {Order, User} from "../../../constants";
import {Button, Flex, Space, Text, TextInput} from "@mantine/core";
import {input_styles} from "../../../constants/theme";
import {useForm} from "@mantine/form";
import axios from "axios";
import {notifications} from "@mantine/notifications";
import {addSubcollectionDoc, getSubcollectionDoc} from "../../../repositories/firebase";
import {COLLECTIONS} from "../../../constants/global";
import {useAppData} from "../../../contexts/AppContext";

// const BASE_URL =
//   "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app";
interface Props {
  order: Order;
  onSubmit: () => void;
  patient: User;
  type: "charge" | "refund" | "void";
}

function RefundPatientForm({ onSubmit, order, patient, type }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  let isChargePatientMode = type === "charge";
  let isVoidPatientMode = type === "void";
  const [isOldPaymentType, setIsOldPaymentType] = useState(false);
  const { user } = useAppData();
  const [baseUrl, setBaseUrl] = useState(process.env.REACT_APP_ENV === 'dev'
      ? 'http://127.0.0.1:5001/fir-34309/us-central1/app' : 'https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app');
  const formatCardNumber = (cardNumber: string) => {
    if (!cardNumber) return "";
    const lastFourDigits = cardNumber.slice(-4);
    const maskedDigits = "*".repeat(cardNumber.length - 4);
    return maskedDigits + lastFourDigits;
  };

  const maskSecurityCode = (securityCode: string) => {
    if (!securityCode) return "";
    return "*".repeat(securityCode.length);
  };

  const form = useForm<any>({
    initialValues: {
      cardNumber: order?.paymentMethod?.cardNumber || "",
      securityCode: order?.paymentMethod?.securityCode || "",
      expiryMonth: order?.paymentMethod?.expirationMonth || "",
      expiryYear: order?.paymentMethod?.expirationYear || "",
      transactionId: "",
      amount: "",
    },
    validate: {
      cardNumber: (value) => (value === "" ? "Card Number required" : null),
      securityCode: (value) => (value === "" ? "Security Code required" : null),
      expiryMonth: (value) =>
        value === "" ? "Expiration Month required" : null,
      expiryYear: (value) => (value === "" ? "Expiration Year required" : null),
      transactionId: (value) =>
        value === "" && !isChargePatientMode ? "Transaction ID required" : null,
      amount: (value) =>
        value === "" && isChargePatientMode ? "Amount required" : null,
    },
  });

  // Old Code For Backward Compatibility
  const handleRefundOld = async (values: any) => {
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${baseUrl}/refundTransaction`, {
        data: {
          cardInfo: {
            cardNumber: values.cardNumber.replace(/\*/g, ""),
            cardCode: order?.paymentMethod?.securityCode,
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          transactionId: values.transactionId,
          amount: order?.totalAmount,
        },
      });

      if (response.data.success) {
        notifications.show({
          title: "Refund request successful!",
          message: response.data.message,
          color: "green",
          autoClose: 10000,
          closeButton: true
        });
        // add logs
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Payment refunded.`,
              description: `Payment has been refunded of amount $${order?.totalAmount} by ${user?.email}.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        notifications.show({
          title: "Refund request failed!",
          message: response.data.message,
          color: "red",
          autoClose: 10000,
          closeButton: true
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Refund request failed!",
        message: error?.response?.data?.message,
        color: "red",
        autoClose: 10000,
        closeButton: true
      });
    } finally {
      setIsSubmitting(false);
    }
    onSubmit();
  };
  const handleChargeOld = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/chargeCreditCard`, {
        data: {
          cardInfo: {
            cardCode: order?.paymentMethod?.securityCode,
            cardNumber: values.cardNumber,
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          address: {
            firstName: patient.firstName,
            lastName: patient.lastName,
            company: "Art of Med",
            address: patient.address.street,
            city: patient.address.city,
            state: patient.address.state,
            zip: patient.address.postalCode,
            country: patient.address.state,
          },
          amount: values?.amount,
        },
      });
      if (response.data.success) {
        notifications.show({
          title: "Payment request submitted successfully!",
          message: response.data.message,
          color: "green",
          autoClose: 10000,
          closeButton: true
        });
        // add logs
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Auto re-fill Payment charged.`,
              description: `Payment has been charged of amount $${values?.amount} by ${user?.email} for auto re-fill.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        notifications.show({
          title: "Payment charge request failed",
          message: response.data.message,
          color: "red",
          autoClose: 10000,
          closeButton: true
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Payment charge request failed!",
        message: error?.response?.data?.message,
        color: "red",
        autoClose: 10000,
        closeButton: true
      });
    } finally {
      setIsSubmitting(false);
    }
    onSubmit();
  };

  // New Code
  const handleRefundNew = async (values: any) => {
    setIsSubmitting(true);
//debugger;
    try {
      const response = await axios.post(`${baseUrl}/refundTransaction-New`, {
        data: {
          cardInfo: {
            cardNumber: values.cardNumber.replace(/\*/g, "").slice(-4),
            //cardCode: order?.paymentMethod?.securityCode, | not required
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          transactionId: values.transactionId,
          amount: order?.totalAmount,
          oid: order?.id,
          customerPaymentProfileId: order?.customerPaymentProfileId,
          customerProfileId: order?.customerProfileId,
        },
      });

      if (response.data.success) {

        notifications.show({
          title: "Refund request successful!",
          message: response.data.responseReasonDescription,
          color: "green",
          autoClose: 10000,
          withCloseButton: true
        });
        let refundAmount = order?.totalAmount as any;
        //debugger;
        if(order?.transactionId!==values.transactionId){
          // may be the admin is trying to refund a different transaction
          // search order-logs sub collection of orders for the transactionId and get the amount .
          // if we don't do this code then the refundAmount will be shown wrong on the order logs
          refundAmount = await refundAmountForTransaction(values.transactionId);
        }
        // add logs
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Payment refunded.`,
              description: `Payment has been refunded of amount $${refundAmount} by ${user?.email} for TID: ${values?.transactionId}.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        let rsp = response.data.responseReasonDescription
        if (response.data.responseReasonDescription?.includes("criteria")) {
          rsp += ".Maybe the transaction is not settled, please try voiding the transaction."
        }
        notifications.show({
          title: "Refund request failed!",
          message: rsp,
          color: "red",
          autoClose: 10000,
          withCloseButton: true
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Refund request failed!",
        message: error?.response?.data?.message,
        color: "red",
        autoClose: 10000,
        withCloseButton: true
      });
    } finally {
      setIsSubmitting(false);
    }
    onSubmit();
  };
  const handleChargeNew = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/chargeCustomerProfile`, {
        customerProfileId: order?.customerProfileId,
        customerPaymentProfileId: order?.customerPaymentProfileId,
        amount: values?.amount,
      }).then((response: any) => {
        if (response.data.success) {
          notifications.show({
            title: "The card has been charged successfully!",
            message: response.data.message,
            color: "green",
            autoClose: 10000,
            withCloseButton: true
          });
          // add logs
          addSubcollectionDoc(
              COLLECTIONS.ORDERS,
              order?.id,
              "order-logs",
              {
                title: `Auto re-fill Payment charged.`,
                description: `Payment has been charged of amount $${values?.amount} by ${user?.email} for auto re-fill. TID: ${response?.data?.transactionId}`,
              },
              {
                createdAt: true,
              }
          );
        } else {
          notifications.show({
            title: "Payment charge request failed",
            message: response.data.message,
            color: "red",
            autoClose: 10000,
            withCloseButton: true
          });
        }
      })
    } catch (error: any) {
      notifications.show({
        title: "Payment charge request failed!",
        message: error?.response?.data?.message,
        color: "red",
        autoClose: 10000,
        withCloseButton: true
      });
    }
    finally {
      setIsSubmitting(false);
    }
    onSubmit();
  }
  const refundAmountForTransaction = async (transId: any) => {
    let refundAmount = "(custom-amount)";
    const orderLogs = await getSubcollectionDoc(COLLECTIONS.ORDERS, order?.id, "order-logs") as any;
    for (let i = 0; i < orderLogs.length; i++) {
      const orderLog = orderLogs[i];
      const description = orderLog.description;
      const match = description.includes(transId);
      if (match) {
        const regexPatterns = [
          /\$\d+(\.\d{2})?/,
          /\$\d+(\.\d{2})?/g,
          /\$\d+/,
          /\$\d+\.\d{2}/
        ];
        for (let i = 0; i < regexPatterns.length; i++) {
          const regex = regexPatterns[i];
          const match = description.match(regex);
          if (match) {
            refundAmount = match[0];
            refundAmount = refundAmount.replace("$", "");
            break;
          }
        }
        break;
      }
    }
    return refundAmount;
  }
  const handleVoid = async (values: any) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(`${baseUrl}/voidTransaction`, {
        data: {
          cardInfo: {
            cardNumber: values.cardNumber.replace(/\*/g, "").slice(-4),
            //cardCode: order?.paymentMethod?.securityCode, | not required
            expiryDate: values.expiryMonth + "/" + values.expiryYear,
          },
          transactionId: values.transactionId,
          amount: order?.totalAmount,
          oid: order?.id,
          customerPaymentProfileId: order?.customerPaymentProfileId,
          customerProfileId: order?.customerProfileId,
        },
      });

      if (response.data.success) {
        //debugger;
        notifications.show({
          title: "Refund request successful!",
          message: response.data.responseReasonDescription,
          color: "green",
          autoClose: 10000,
          withCloseButton: true
        });
        let refundAmount = order?.totalAmount as any;
        //debugger;
        if(order?.transactionId!==values.transactionId){
            // may be the admin is trying to refund a different transaction
            // search order-logs sub collection of orders for the transactionId and get the amount .
            // if we don't do this code then the refundAmount will be shown wrong on the order logs
            refundAmount = await refundAmountForTransaction(values.transactionId);
        }
        // add logs
        await addSubcollectionDoc(
            COLLECTIONS.ORDERS,
            order?.id,
            "order-logs",
            {
              title: `Payment Refunded(V).`,
              description: `Payment has been refunded of amount $${refundAmount} by ${user?.email} for TID: ${values.transactionId}.`,
            },
            {
              createdAt: true,
            }
        );
      } else {
        notifications.show({
          title: "Refund request failed!",
          message:response.data.responseReasonDescription,
          color: "red",
          autoClose: 10000,
          withCloseButton: true
        });
      }
    } catch (error: any) {
      notifications.show({
        title: "Refund request failed!",
        message: error?.response?.data?.message,
        color: "red",
        autoClose: 10000,
        withCloseButton: true
      });
    } finally {
      setIsSubmitting(false);
    }
    onSubmit();
  };

  useEffect(() => {
    if(order){
      if((order?.paymentMethod?.cardNumber && !order?.paymentMethod?.cardNumber?.includes("xxxx-")) || order?.mode===undefined){
        setIsOldPaymentType(true);
      }
    }
  }, []);

  const handleSubmit = async (values: any, type: string) => {
    debugger;
    if (type === "charge") {
      if(isOldPaymentType === false) {
        handleChargeNew(values);
      }else{
        handleChargeOld(values);
      }
    } else if (type === "void") {
      handleVoid(values);
    } else {
      if(isOldPaymentType === false) {
        handleRefundNew(values);
      }else{
        handleRefundOld(values);
      }
    }
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values, type))}>
      <Flex direction={"column"} gap={"lg"}>
        <Flex direction={"column"}>
          <Text size="xl">
            Name:{" "}
            <strong>{order?.paymentMethod?.cardHolderName || "N/A"}</strong>
          </Text>
          <Space h={"xs"} />

          {isChargePatientMode ? (
            <TextInput
              maw={"200px"}
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Amount"
              label="Amount"
              name="amount"
              type="number"
              {...form.getInputProps("amount")}
            />
          ) : (
            <Text size="xl">
              Total Amount:{" "}
              <strong>
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(order?.totalAmount || 0)}
              </strong>
            </Text>
          )}
        </Flex>

        {!isChargePatientMode || isOldPaymentType ? (
            <><Flex gap={"md"}>
          <TextInput
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Card Number"
            label="Card Number"
            name="cardNumber"
            readOnly
            {...form.getInputProps("cardNumber")}
            value={formatCardNumber(order?.paymentMethod?.cardNumber || "")}
          />
          <TextInput
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Security Code"
            label="Security Code"
            name="securityCode"
            readOnly
            {...form.getInputProps("securityCode")}
            value={maskSecurityCode(order?.paymentMethod?.securityCode || "")}
          />
        </Flex>
              <Flex gap={"md"}>
        <TextInput
            // maw={"200px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Exp Month"
            label="Expiration Month"
            name="expiryMonth"
            readOnly
            {...form.getInputProps("expiryMonth")}
        />
        <TextInput
            // maw={"200px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Exp Year"
            label="Expiration Year"
            name="expiryYear"
            readOnly
            {...form.getInputProps("expiryYear")}
        />
      </Flex>
      </>) : null}
        {isChargePatientMode && (isOldPaymentType === false) ? (<>
          <div>Customer Profile ID: {order?.customerProfileId}</div>
          <div>Customer Payment Profile ID: {order?.customerPaymentProfileId}</div>
          <div>Customer Card Details: {'XXXX-XXXX-XXXX-'+order?.cardLast4Digits}</div>
        </>) : !isChargePatientMode && (
          <TextInput
            maw={"640px"}
            radius={"md"}
            styles={input_styles}
            size="xl"
            placeholder="Transaction ID"
            label="Transaction ID"
            name="transactionId"
            {...form.getInputProps("transactionId")}
          />
        )}

        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}>
          {isChargePatientMode ? "Charge" : "Refund"}
        </Button>
      </Flex>
    </form>
  );
}
export default RefundPatientForm;
